import React from 'react';
import { createRoot } from 'react-dom/client';
import './stylesheet/application.css';
import '@customink/design-tokens/build/ci_light/css/variables.css';
import '@customink/pigment-react/lib/fonts.css';
import * as Sentry from '@sentry/react';
import { knownAuthErrors } from '~/contexts/Auth/error';
import './adapters/newrelic/newrelic';
import App from './app';

const isProduction = import.meta.env.MODE === 'production';

Sentry.init({
    release: import.meta.env.VITE_RELEASE,
    dsn: import.meta.env.ACCOUNTS_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
            maskAllText: isProduction,
            maskAllInputs: isProduction,
            blockAllMedia: isProduction,
        }),
    ],
    beforeSend(event) {
        // do not log known auth related errors
        if (
            event.exception?.values?.every((exception) => exception.value && knownAuthErrors.includes(exception.value))
        ) {
            return null;
        }
        return event;
    },
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: isProduction ? 0.2 : 1.0,
});

// non-null assertion is officially recommended  https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root_accounts_ui')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
