import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { ArrowNextIcon, Button, useMobileBreakpoint } from '@customink/pigment-react';
import { stopPropagation } from '~/adapters/browser/stopPropagation';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import { Design } from '~/adapters/services/accounts/designs/types';
import { trackEvent } from '~/adapters/tracking/tracking';
import { useFeatureFlagEnabled } from '~/adapters/signaler/hooks';
import { gaCategoryNew } from '../tracking';
import { OrderOptionsMenu } from './OrderOptionsMenu';

type OrderOptionsButtonProps = {
    design: Design;
    isTutorialOrderOptionsMenuOpen: boolean;
    handleTutorialOrderOptionsMenuClose: EmptyFunction;
};

export const OrderOptionsButton: FC<OrderOptionsButtonProps> = ({
    design,
    isTutorialOrderOptionsMenuOpen,
    handleTutorialOrderOptionsMenuClose,
}) => {
    const isMobile = useMobileBreakpoint();
    const isAddToCartProminenceEnabled = useFeatureFlagEnabled('add_to_cart_prominence_v2');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleButtonClick = useCallback((ev: MouseEvent<HTMLElement>) => {
        stopPropagation(ev);
        trackEvent({ category: gaCategoryNew, action: 'Button Click', label: 'View Order Options' });
        setAnchorEl(ev.currentTarget);
        handleTutorialOrderOptionsMenuClose();
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        handleTutorialOrderOptionsMenuClose();
    }, []);

    const [buttonElement, setButtonElement] = useState<HTMLElement | null>(null);
    useEffect(() => {
        if (isTutorialOrderOptionsMenuOpen && buttonElement) {
            setAnchorEl(buttonElement);
        }
    }, [isTutorialOrderOptionsMenuOpen]);

    const buttonLabel = isMobile ? 'Order Options' : `View Order${isAddToCartProminenceEnabled ? 'ing' : ''} Options`;

    return (
        <>
            <Button
                ref={setButtonElement}
                onClick={handleButtonClick}
                variant="secondary"
                fullWidth
                endIcon={<ArrowNextIcon />}
                sx={{
                    justifyContent: 'space-between',
                    '.MuiButton-endIcon	': { marginLeft: 0 },
                    // we want to simulate :active CSS, but there's no way to imperatively activate an elem (you can focus, but that's different)
                    bgcolor: isTutorialOrderOptionsMenuOpen ? designTokens.color.secondary.active.value : undefined,
                    color: isTutorialOrderOptionsMenuOpen ? designTokens.color.shade.white.value : undefined,
                }}
            >
                {/* empty element so that the label is centered, but icon is at end */}
                <span />
                <span>{buttonLabel}</span>
            </Button>
            <OrderOptionsMenu design={design} anchorElement={anchorEl} handleClose={handleClose} />
        </>
    );
};
