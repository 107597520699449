import { Box, Container, ScreenReadersOnly, Select, Stack, Typography } from '@customink/pigment-react';
import { Loader } from '~/components/Loader/loader';
import { usePage } from '~/adapters/browser/usePage';
import { Title } from '~/pages/Settings/title';
import { useGetCertificates, useGetCustomer } from '~/adapters/services/accounts/taxExemptionsECM/hooks';
import { PortalOrBusinessAddressButton } from '~/pages/Settings/tabs/TaxExemptionECM/components/Portal/PortalOrBusinessAddressButton';
import { CushionedBox } from '~/components/Layout/CushionedBox';
import { ErrorBoundary } from '~/components/ErrorBoundary';
import { trackEvent } from '~/adapters/tracking/tracking';
import { CertificateManagement } from '~/pages/Settings/tabs/TaxExemptionECM/components/Certificate/CertificateManagement';
import { SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { CertificateStatus } from '~/adapters/services/accounts/taxExemptionsECM/types';
import { TypedMenuItem } from '~/adapters/designSystem/TypedMenuItem';

const ScreenReaderHeading = () => (
    <ScreenReadersOnly>
        <h2>Tax Exemption</h2>
    </ScreenReadersOnly>
);

const CertStatusMenuItem = TypedMenuItem<CertificateStatus | 'all'>;

const statusOrder = ['pending', 'valid', 'expired', 'invalid', 'revoked'];
const getStatusIndex = (status: string) => statusOrder.indexOf(status);

function TaxExemptionInner() {
    usePage({ title: Title, reportPath: '/accounts/tax_exemption' });

    const [certStatusFilter, setCertStatusFilter] = useState<CertificateStatus | 'all'>('all');
    const handleCertFilterChange = useCallback((event: SelectChangeEvent) => {
        setCertStatusFilter(event.target.value as CertificateStatus | 'all');
    }, []);

    // Load in parallel here in parent component
    const certificates = useGetCertificates();
    const customer = useGetCustomer();

    const certificatesCount = useMemo(
        () => ({
            all: certificates.data?.length,
            pending: certificates.data?.filter((c) => c.cinkStatus === 'pending').length,
            valid: certificates.data?.filter((c) => c.cinkStatus === 'valid').length,
            expired: certificates.data?.filter((c) => c.cinkStatus === 'expired').length,
            invalid: certificates.data?.filter((c) => c.cinkStatus === 'invalid').length,
            revoked: certificates.data?.filter((c) => c.cinkStatus === 'revoked').length,
        }),
        [certificates.data],
    );

    // Since there's no pagination, we agreed that local filtering is enough here and might be faster for the user, as opposed to BE filtering (but BE supports it, it's trivial to add if needed).
    const filteredCertificates = useMemo(() => {
        if (!certificates.data) {
            return [];
        }
        const certificatesData = certificates.data.filter(
            (c) => certStatusFilter === 'all' || c.cinkStatus === certStatusFilter,
        );
        return certificatesData.sort((a, b) => {
            return getStatusIndex(a.cinkStatus) - getStatusIndex(b.cinkStatus);
        });
    }, [certificates.data, certStatusFilter]);

    if (certificates.isPending || customer.isPending) {
        return <Loader title="Loading..." devLabel="Tax exemption API" />;
    }

    if (customer.isError) {
        throw customer.error;
    }
    if (certificates.isError) {
        throw certificates.error;
    }

    if (certificates.data.length) {
        return (
            <Box>
                <ScreenReaderHeading />
                <Stack mb={2} spacing={2} direction="row" justifyContent="flex-end" flexWrap="wrap">
                    <PortalOrBusinessAddressButton
                        text="Add Certificates"
                        variant="secondary"
                        customer={customer.data}
                        onCustomerUpdate={customer.refetch}
                    />
                    <Select
                        data-testid="cert filter"
                        value={certStatusFilter}
                        onChange={handleCertFilterChange}
                        sx={{ minWidth: 150 }}
                    >
                        <CertStatusMenuItem value="all">All ({certificatesCount.all})</CertStatusMenuItem>
                        <CertStatusMenuItem value="pending">Pending ({certificatesCount.pending})</CertStatusMenuItem>
                        <CertStatusMenuItem value="valid">Valid ({certificatesCount.valid})</CertStatusMenuItem>
                        <CertStatusMenuItem value="expired">Expired ({certificatesCount.expired})</CertStatusMenuItem>
                        <CertStatusMenuItem value="invalid">Invalid ({certificatesCount.invalid})</CertStatusMenuItem>
                        <CertStatusMenuItem value="revoked">Revoked ({certificatesCount.revoked})</CertStatusMenuItem>
                    </Select>
                </Stack>
                <CertificateManagement certificates={filteredCertificates} certStatusFilter={certStatusFilter} />
            </Box>
        );
    }

    return (
        <Box>
            <ScreenReaderHeading />
            <CushionedBox>
                <Container maxWidth="md">
                    <Typography variant="bodyShort2" paragraph>
                        Once we&apos;ve verified your tax exempt status, you can apply it to qualifying purchases during
                        checkout.
                    </Typography>
                    <PortalOrBusinessAddressButton
                        text="Verify Tax Exempt Status"
                        variant="primary"
                        customer={customer.data}
                        onCustomerUpdate={customer.refetch}
                        onClick={() => {
                            trackEvent({
                                category: 'Tax Exempt',
                                action: 'Click',
                                label: 'Verify Tax Exempt Status',
                            });
                        }}
                    />
                </Container>
            </CushionedBox>
        </Box>
    );
}

export function TaxExemptionTab() {
    return (
        <ErrorBoundary errorMessage="An error occured while loading your Tax Exemptions overview">
            <TaxExemptionInner />
        </ErrorBoundary>
    );
}
