import { MouseEvent } from 'react';
import ReactGA from 'react-ga';
import { EmptyFunction } from '~/adapters/typescript/emptyFunction';
import setLocation from '../browser/setLocation';
import { getUserId } from './userId';
import { AnalyticsConstants } from './AnalyticsConstants';

type CustomField = Record<string, string | number | boolean>;

export interface AnalyticsEvent {
    action: string;
    category?: string;
    label?: string;
    event?: string;
    dimension?: CustomField;
    customMetrics?: CustomField;
    hitCallback?: EmptyFunction;
}

export const CATEGORY = 'accounts';

/**
 * Push Virtual Page View (PVP) for GA4 to Data Layer. Mimics https://github.com/customink/metrics/blob/master/src/metrics/google.js#L41
 * @param dataObject object to be pushed to dataLayer
 */
export const addDataLayerEvent = (dataObject: Record<string, any>): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataObject);
};

export const trackEvent = (a: AnalyticsEvent): void => {
    const commonEventObject = {
        ...(a.event === 'interaction'
            ? {
                  interaction_category: a.category ?? CATEGORY,
                  interaction_action: a.action,
                  interaction_label: a.label ?? '',
              }
            : { eventCategory: a.category ?? CATEGORY, eventAction: a.action, eventLabel: a.label ?? '' }),
        [AnalyticsConstants.userId]: getUserId(),
        ...a.dimension,
        ...a.customMetrics,
    };
    addDataLayerEvent({
        event: a.event === 'interaction' ? a.event : 'customEvent',
        ...commonEventObject,
    });
    return ga('send', {
        ...commonEventObject,
        hitType: 'event',
        nonInteraction: false,

        ...(a.hitCallback ? { hitCallback: a.hitCallback } : {}),
    });
};

export const trackPageView = (
    title: string,
    path: string,
    accountId: number | null,
    accountCompoundId: string,
): void => {
    // requirements for this event are in this monday ticket https://customink.monday.com/boards/5878886849/pulses/7363942495/posts/3430811253
    addDataLayerEvent({
        event: 'view_account',
        account_id: accountId?.toString(),
        account_token: accountCompoundId,
        pageURL: path,
        pageTitle: title,
    });

    ReactGA.set({ [AnalyticsConstants.accountCompoundId]: accountCompoundId });
    ga('send', 'pageview', path);
};

// fallback timeout to continue with navigation in case GA fails to be sent
export const NAVIGATION_BLOCKING_TIMEOUT = 500; // [ms]

/**
 * Prevent native browser navigation to link href, ensure that GA event is successfully sent, and then redirect to the href.
 * Meant to be used in onClick callback for anchor links.
 */
export const linkTrackEvent = (
    event: MouseEvent<HTMLElement>,
    { hitCallback, ...analyticsEvent }: AnalyticsEvent,
): void => {
    event.preventDefault();
    const linkHref = (event.currentTarget as HTMLLinkElement).href;
    const redirectHitCallback = () => {
        hitCallback?.();
        setLocation(linkHref);
    };
    // navigation will happen in GA3 hitCallback, or after the fallback timeout
    setTimeout(redirectHitCallback, NAVIGATION_BLOCKING_TIMEOUT);
    trackEvent({ hitCallback: redirectHitCallback, ...analyticsEvent });
};

export function trackABTest(featureFlagName: string, variationValue: string, launchDate = '') {
    addDataLayerEvent({
        event: 'ab_test_group',
        ab_test_location: 'accounts',
        ab_test_name: `${launchDate} ${featureFlagName}`,
        ab_test_group: variationValue,
    });
}
