import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { JSErrors } from '@newrelic/browser-agent/features/jserrors';
import { Metrics } from '@newrelic/browser-agent/features/metrics';
import { GenericEvents } from '@newrelic/browser-agent/features/generic_events';
import { PageViewEvent } from '@newrelic/browser-agent/features/page_view_event';
import { PageViewTiming } from '@newrelic/browser-agent/features/page_view_timing';
import { SessionTrace } from '@newrelic/browser-agent/features/session_trace';
import { Spa } from '@newrelic/browser-agent/features/spa';

const browser = new BrowserAgent({
    init: {
        distributed_tracing: { enabled: true },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ['bam.nr-data.net'] },
    },
    loader_config: {
        accountID: import.meta.env.ACCOUNTS_NEWRELIC_ACCOUNT_ID,
        trustKey: import.meta.env.ACCOUNTS_NEWRELIC_TRUST_KEY,
        agentID: import.meta.env.ACCOUNTS_NEWRELIC_AGENT_ID,
        licenseKey: import.meta.env.ACCOUNTS_NEWRELIC_LICENSE_KEY,
        applicationID: import.meta.env.ACCOUNTS_NEWRELIC_APPLICATION_ID,
    },
    info: {
        beacon: 'bam.nr-data.net',
        errorBeacon: 'bam.nr-data.net',
        licenseKey: import.meta.env.ACCOUNTS_NEWRELIC_LICENSE_KEY,
        applicationID: import.meta.env.ACCOUNTS_NEWRELIC_APPLICATION_ID,
        sa: true,
    },

    features: [JSErrors, Metrics, GenericEvents, PageViewEvent, PageViewTiming, SessionTrace, Spa],
});

export default browser;
